

<template>
    <div>
        <modal height="auto" :name="modal_name" class="final-modal" transition="nice-modal-fade">
            <div class="v-modal-content p-0">
                <div class="v-modal-header v-modal-header-new-back" style="background-color:#00448b !important">
                    <span class="v-modal-dialog-title v-modal-title fw-600 fs-14 py-3 ml-auto mr-auto text-uppercase" style="color: #fff !important;letter-spacing:1px;">{{subscription_text}}</span>
                    <button  type="button" class="pointer position-absolute" style="background-color: #00448b !important;outline:none; right: 0; top: 10px;" aria-label="Close" @click="hideSubscription()">
                        <span aria-hidden="true">
                            <i class="icon icon-close" style="color:white;margin:10px 10px 0 0;"></i>
                        </span>
                    </button>
                </div>
                <div class="m-4">
                    <div class="v-modal-body pt-0 pb-0" style="max-height:430px;overflow-y:auto;overflow-x:auto;">
                        <img src="/static/images/Parrot 1@3x.png" style="margin:0 auto;display:block;" width="100px" alt="">
                        <!-- <h3 class="text-center fs-14 fw-600 mb-0 p-3 mt-2">COMING SOON!</h3> -->
                        <p v-if="subscription_text == 'Subscription'" class="text-center fw-400 mt-5" style="margin-top: -10px;">Get in touch with our support team to manually enable <span class="fw-600 fs-14">  {{product_name}} </span>product.</p>
                        <p v-else class="text-center fw-400 mt-5" style="margin-top: -10px;">Employee will no longer able to access<span class="fw-600 fs-14">  {{product_name}}. </span><br> Are you sure want to unsubscribe the product?.</p>
                        <!-- <p v-if="subscription_text == 'UnSubscription'" class="text-center fw-500">Get in touch with our support team to manually enable this product</p> -->
                        <div v-if="subscription_text == 'Subscription'" class="d-flex flex-column px-10">
                            <span class="mb-1 mt-2 fw-600 fs-14" style="color: #00448b;">Add Comment</span>
                            <textarea style="border:1px solid #000;height:70px;border-radius:5px;padding:5px;" :maxlength="200" v-model="comment" placeholder="Enter text here"></textarea>
                            <span style="place-self:end">{{comment.length}}/200</span>
                            <h1 class="text-danger fs-14">{{error_text}}</h1>
                        </div>
                    </div>
                    <div class="v-modal-dialog-actions text-center" style="border-radius: 0px 0px 15px 15px;padding:15px;">
                        <button class="btn btn-outline-secondary" @click="hideSubscription()" style="width: 120px;border-radius: 5px;">Cancel</button>
                        <button class="btn btn-new-primary ml-5" @click="sendSubscription" style="width: 120px;border-radius: 5px;">Send</button>
                        <!-- <a class="btn btn-danger btn-smm text-white" style="text-transform: uppercase !important;font-size: 12px !important;border-radius:5px !important;" @click="hideSubscription()">CANCEL</a>
                        <a class="btn btn-secondary btn-smm text-white ml-2" style="text-transform: uppercase !important;font-size: 12px !important;border-radius:5px !important;" @click="sendSubscription()">SEND</a> -->
                    </div> 
                </div>     
            </div>
        </modal>
    </div>
</template>

<script>

export default {
    props:['modal_name', 'product_id', 'subscription_text', 'error_text', 'product_name'],
    data(){
        return{
            comment: ''
        }
    },
    methods: {
        hideSubscription(){
            this.$emit('hide-subscription-modal');
        },
        sendSubscription() {
            let data = {
                about: this.comment,
                product_id: this.product_id,
                company_id: this.$route.params.company_id
            }
            this.$emit('sendSubscription', data);
        }

    }
}

</script>

<style scoped>
.btn-outline-secondary {
    color: #e82828;
    border: 1px solid #e82828 !important;
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #e82828;
    border-color: #e82828 !important;
}
.v-modal-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.v-modal-content{
    padding: 5px;
}
.v-modal-body div form{
    display: flex;
    flex-direction: column;
}

.v-modal-body div input{
    margin-bottom: 10px;
    padding: 10px;
    outline: none;
    border-radius: 5px;
    border: 1px solid black;
    opacity: 0.71
}

</style>
